import { gql, useQuery } from '@apollo/client';
import { Checkbox } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownArrowIcon from 'components/icons/DropdownArrowIcon';

const ousQuery = gql`
  query {
    ous
  }
`;

interface IOrgregProps {
  onUnitsChange: (units: Array<string>) => void;
}

const OrganizationalUnit = ({ unit, level, onCheck, checkedUnits}: any) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheck(unit.id, event.target.checked);
  };

  return (
    <div>
      <div style={{ paddingLeft: `${level * 4}rem` }}>
        {unit.children && unit.children.length !== 0 && 
          <div 
            typeof='button'
            style={{ display: "inline-block"}}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <DropdownArrowIcon
              selected={!isCollapsed}
              height="12"
              width="22"
              viewBox='0 0 1 20'
              
            />
          </div>
        }
        <Checkbox
          checked={!!checkedUnits.includes(unit.id)}
          onChange={handleCheck}
          sx={{
            color: "#196F7E",
            "&.Mui-checked": {
              color: "#196F7E",
            },
            '& .MuiSvgIcon-root': { fontSize: 23 }
          }}
          />
            <div
              style={{ display: "inline-block", marginRight: "0.5rem" }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              {unit.name[lang]}
            </div>
        </div>
      <div
      style={{
        display: isCollapsed ? "none" : "block",
      }}
      >
        {unit.children && 
          unit.children.map((child: any) => (
            <OrganizationalUnit
              key={child.id}
              unit={child}
              level={level + 1}
              onCheck={onCheck}
              checkedUnits={checkedUnits}
            />
          ))}
       </div>
      </div>
  );
};

const OrgregTree: React.FC<IOrgregProps> = ({ onUnitsChange }) => {
  const { t } = useTranslation(['admin']);
  const { data, loading, error } = useQuery(ousQuery);
  const [checkedUnits, setCheckedUnits] = useState<Array<string>>([]);

  if (loading) {
    return <div>{t('ous.loadingOus')}</div>;
  }
  if (error) {
    return <div>{t('ous.loadingError')}</div>;
  }

  const findUnitById = (unit: any, id: string): any => {
    if (unit.id === id) return unit;
    if (unit.children) {
      for (let child of unit.children) {
        const result = findUnitById(child, id);
        if (result) return result;
      }
    }
    return null;
  };

  const handleCheck = (id: string, isChecked: boolean) => {
    setCheckedUnits(prev => {
      let newChecked: Array<string> = [...prev];

      if (isChecked) {
        if (!newChecked.includes(id)) {
          newChecked.push(id);
        }
      } else {
        newChecked = newChecked.filter(item => item !== id);
      }
      

      const checkChildren = (children: any[], shouldCheck: boolean) => {
        children.forEach(child => {
          if (shouldCheck) {
            if (!newChecked.includes(child.id)) {
              newChecked.push(child.id);
            }
          } else {
            newChecked = newChecked.filter(item => item !== child.id);
          }
          if (child.children) {
            checkChildren(child.children, shouldCheck);
          }
        });
      };

      const unit = findUnitById(data.ous, id);
      if (unit?.children) {
        checkChildren(unit.children, isChecked);
      }

      onUnitsChange(newChecked)
      return newChecked;
    });
  };

  const ou = data.ous;

  return (
      <div>
        {ou.children.map((child: any) => (
          <OrganizationalUnit
            key={child.id}
            unit={child}
            level={0}
            onCheck={handleCheck}
            checkedUnits={checkedUnits}
          />
        ))}
      </div>
  );
};

export default OrgregTree;
