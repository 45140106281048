import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import theme from 'theme';

const CensusHeaderButton: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <ButtonGroup 
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '89.5rem',
        height: '5.5rem', 
        borderRadius: 12,
        marginTop: '1rem',
        marginLeft: '4.8rem',
      }}>
      {props.children}
    </ButtonGroup>
  )
};

interface CensusButtonProps {
  side: string;
  onClick: () => void;
  active: boolean,
}

const CensusButton: React.FC<CensusButtonProps> = (props) => {
  const { side, onClick, active } = props
  const { t } = useTranslation()

  const activeColor = theme.colors.lightTurquoise
  const inactiveColor = theme.colors.lightBlueGray 

  const buttonColor = active ? activeColor : inactiveColor

  let buttonText
  if (side === 'left') {
    buttonText = t('census.uploadCensusFromFile')
  } else {
    buttonText = t('census.uploadCensusFromFSDFO')    
  }

  return (
    <Button 
      variant='text'
      sx={{
        fontSize: 16,
        width: '50%',
        color: theme.colors.lightBlack,
        textTransform: 'none',
        borderRadius: 12,
        backgroundColor: buttonColor,
      }} 
      onClick={onClick}>
        {buttonText}
    </Button>
  )
}

export { CensusHeaderButton, CensusButton };
