import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { ApolloQueryResult, gql } from '@apollo/client';
import { withApollo, WithApolloClient } from '@apollo/client/react/hoc';
import {
  Form,
  Field,
  FormRenderProps,
  FieldRenderProps,
} from 'react-final-form';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

import Button, { ButtonContainer } from 'components/button';
import { CensusHeaderButton, CensusButton } from 'components/button/CensusButtonHeader';
import { FormField, RadioButtonGroup } from 'components/form';
import OrgregTree from './MakeOrgregTree';
import { translateBackendError } from 'utils';

const styles = (theme: any) => ({
  modalSize: {
    minHeight: '55rem',
  },

  hiddenFileInput: {
    opacity: 0,
    position: 'absolute',
    pointerEvents: 'none',
    // alternative to pointer-events, compatible with all browsers, just make it impossible to find
    width: '1px',
    height: '1px',
  },

  size: {
    display: 'flex',
    minWidth: '26rem',
  },

  button: {
    width: '9rem',
    height: '4rem',
    border: `0.3rem solid`,
    borderRadius: '0.4rem',
    cursor: 'pointer',
    display: 'block',
    objectFit: 'contain',
    fontFamily: 'Arial',
    fontSize: '1.8rem',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.7',
    letterSpacing: 'normal',
    textAlign: 'center',
    transition: 'background 100ms ease-in',
  },

  secondary: {
    background: theme.secondaryBtnBgColor,
    borderColor: theme.secondaryBtnBorderColor,
    color: theme.secondaryBtnColor,
  },

  fileNameBox: {
    width: '39.2rem',
    height: '4rem',
    border: `0.2rem solid`,
    borderRadius: '0.4rem',
    borderColor: '#dfdddd',
    backgroundColor: '#ffffff',
    marginRight: '2rem',
    paddingLeft: '1.2rem',
  },

  formHeader: {
    fontFamily: 'Arial',
    fontSize: '2.4rem',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.88',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#555555',
  },
});

const UploadCensusFileMutation = gql`
  mutation ($censusFile: Upload!, $pollbookId: UUID!) {
    uploadCensusFile(censusFile: $censusFile, pollbookId: $pollbookId) {
      success
      code
      message
    }
  }
`;

const ImportCensusMutation = gql`
  mutation ($ouIds: [String]!, $pollbookId: UUID!) {
    importCensus(ouIds: $ouIds, pollbookId: $pollbookId) {
      success
      code
      message
    }
  }
`;

interface IUploadCensusFileResponse {
  uploadCensusFile: {
    success: boolean;
    code?: string;
    message?: string;
    numOk?: number;
    numFailed?: number;
  };
}

interface IImportCensusResponse {
  importCensus: {
    success: boolean;
    code?: string;
    message?: string;
    numOk?: number;
    numFailed?: number;
  };
}

interface IProps extends WithTranslation {
  closeAction: (proc: IUploadCensusFileModalStatus) => void;
  header: string | React.ReactElement<any>;
  pollBooks: any;
  groupId: string;
  refetchData?: (
    variables?: { id: string } | undefined
  ) => Promise<ApolloQueryResult<any>>;
  classes: any;
  pollbookIntegration: boolean;
}

type PropsInternal = WithApolloClient<IProps>;

interface IState {
  censusFile: File | null;
  fileName?: string;
  isUploading: boolean;
  fileButton: boolean;
  importButton: boolean; 
  unitsChecked: Array<string>;
}

interface IHTMLInputEvent extends React.FormEvent {
  target: HTMLInputElement & EventTarget;
}

export interface IUploadCensusFileModalStatus {
  success: boolean;
  message?: string;
  numOk?: number;
  numFailed?: number;
}

export interface IImportCensusModalStatus {
  success: boolean;
  message?: string;
  numOk?: number;
  numFailed?: number;
}

class UploadCensusFileModal extends React.Component<
  PropsInternal,
  IState,
  IHTMLInputEvent
> {
  constructor(props: PropsInternal) {
    super(props);

    this.state = {
      censusFile: null,
      fileName: '',
      isUploading: false,
      fileButton: false, 
      importButton: true,
      unitsChecked: [],
    };

    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.fileInputWrapper = this.fileInputWrapper.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleUnitsChange = this.handleUnitsChange.bind(this);
  }

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  private handleKeyPress(e: KeyboardEvent) {
    // Close the modal on escape
    if (e.keyCode === 27) {
      const { closeAction } = this.props;
      const status: IUploadCensusFileModalStatus = { success: false };
      closeAction(status);
    }
  }

  public onChange(e: IHTMLInputEvent) {
    if (e !== undefined) {
      if (e.target.files !== null) {
        this.setState({
          censusFile: e.target.files[0],
          fileName: e.target.files[0].name,
        });
      }
    }
  }

  public handleUnitsChange(units: Array<string>) {
    this.setState({unitsChecked: units})
  }

  private censusFromFile(classes: any, fileName: string | undefined) {
    return (
      <div>
        <br />
        <p className={classes.formHeader}>
          <Trans>census.chooseFile</Trans>
        </p>

        <div className={classes.size}>
          <input
            className={classes.fileNameBox}
            name="filename"
            type="text"
            value={fileName}
            disabled
          />

          <Field
            name="censusFile"
            validate={(value: any) => (value ? undefined : 'Required')}
          >
            {this.fileInputWrapper}
          </Field>
        </div>
      </div>
    )
  }

  async onSubmit(values: any) {
    this.setState({
      isUploading: true,
    });
    const { client, closeAction, i18n, pollBooks, refetchData, t } = this.props;
    const { censusFile, importButton, unitsChecked } = this.state;

    const lang: string = i18n.language;

    if (!client) {
      this.setState({ isUploading: false });
      const errorMessage = t('census.errors.backend.unknown');
      const status: IUploadCensusFileModalStatus = {
        success: false,
        message: errorMessage,
        numOk: 0,
        numFailed: 0,
      };
      closeAction(status);
    } else if (importButton) {
      await client
        .mutate<IImportCensusResponse>({
          mutation: ImportCensusMutation,
          variables: 
            {
              ouIds: unitsChecked,
              pollbookId: values.pollbookId,
            }
        })
        .then((result) => {
          const response =
            result && result.data && result.data.importCensus;

          this.setState({ isUploading: false });

          if (!response || !response.success) {
            let errorMessage = t('census.errors.backend.unknownImport');
            if (response && response.code) {
              errorMessage = translateBackendError({
                errorCode: response.code,
                t,
                codePrefix: 'census.errors.backend',
              });
            }
            const status: IImportCensusModalStatus = {
              success: false,
              message: errorMessage,
            };
            closeAction(status);
          } else {
            if (refetchData !== undefined) {
              refetchData();
            }
            const message = t('census.importStarted', {
              pollbookName: pollBooks[values.pollbookId].name[lang],
            });
            const status: IImportCensusModalStatus = {
              success: true,
              message,
              numOk: response.numOk,
              numFailed: response.numFailed,
            };
            closeAction(status);
          }
        })
        .catch(() => {
          this.setState({ isUploading: false });
          const errorMessage = t('census.errors.backend.unknown');
          const status: IUploadCensusFileModalStatus = {
            success: false,
            message: errorMessage,
            numOk: 0,
            numFailed: 0,
          };
          closeAction(status);
        }
      );
    } else {
      await client
        .mutate<IUploadCensusFileResponse>({
          mutation: UploadCensusFileMutation,
          variables: 
            {
              censusFile: censusFile,
              pollbookId: values.pollbookId,
            }
        })
        .then((result) => {
          const response =
            result && result.data && result.data.uploadCensusFile;

          this.setState({ isUploading: false });

          if (!response || !response.success) {
            let errorMessage = t('census.errors.backend.unknown');
            if (response && response.code) {
              errorMessage = translateBackendError({
                errorCode: response.code,
                t,
                codePrefix: 'census.errors.backend',
                tOptions: {
                  mimetype: censusFile && censusFile.type,
                },
              });
            }
            const status: IUploadCensusFileModalStatus = {
              success: false,
              message: errorMessage,
            };
            closeAction(status);
          } else {
            if (refetchData !== undefined) {
              refetchData();
            }
            const message = t('census.uploadSuccessful', {
              pollbookName: pollBooks[values.pollbookId].name[lang],
            });
            const status: IUploadCensusFileModalStatus = {
              success: true,
              message,
              numOk: response.numOk,
              numFailed: response.numFailed,
            };
            closeAction(status);
          }
        })
        .catch(() => {
          this.setState({ isUploading: false });
          const errorMessage = t('census.errors.backend.unknown');
          const status: IUploadCensusFileModalStatus = {
            success: false,
            message: errorMessage,
            numOk: 0,
            numFailed: 0,
          };
          closeAction(status);
        }
      );
    }
  }

  private fileInputWrapper(fieldProps: FieldRenderProps<any, any>) {
    const { classes } = this.props;
    const onChangeWrapper = (e: IHTMLInputEvent) => {
      fieldProps.input.onChange(e);
      this.onChange(e);
    };

    const labelClassNames = classNames({
      [classes.button]: true,
      [classes.secondary]: true,
    });

    return (
      <div className={classes.size}>
        <Field
          name="censusFile"
          id="censusFile"
          validate={(value: any) => (value ? undefined : 'Required')}
          className={`${classes.hiddenFileInput} file-input`}
          onChange={onChangeWrapper}
          component="input"
          type="file"
        />
        {/* TODO convert to button */}
        <label htmlFor="censusFile" className={labelClassNames}>
          <Trans>general.chooseFile</Trans>
        </label>
      </div>
    );
  }

  private changeForm = (on: boolean) => {
    this.setState({
      importButton: on,
    });
  };

  private renderForm(formProps: FormRenderProps) {
    const { handleSubmit, pristine, invalid } = formProps;
    const { classes, pollBooks, pollbookIntegration } = this.props;
    const { fileName, isUploading, importButton } = this.state;
    const { i18n } = this.props;
    const lang = i18n.language;

    // Wrapper to call preventDefault on submit
    const submitWrapper = (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit(event);
    };

    const submitWrapperUnits = (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      handleSubmit(event);
    };

    // Create the pollbook radio button options
    const pollBookOptions: any = [];
    Object.keys(pollBooks).forEach((pollBookID) => {
      if (pollBooks[pollBookID].active) {
        pollBookOptions.push({
          label: pollBooks[pollBookID].name[lang],
          value: pollBooks[pollBookID].value,
          id: pollBooks[pollBookID].value,
        });
      }
    });
    return (
      <div>
        {pollbookIntegration &&
        <CensusHeaderButton>
            <CensusButton side='left' active={this.state.fileButton} onClick={() => this.changeForm(false)} />
            <CensusButton side='right' active={this.state.importButton} onClick={() => this.changeForm(true)} />
        </CensusHeaderButton>}
        <Container>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            width: '100%' 
            }}>
            <div>
              <form>
                <br />
                <p className={classes.formHeader}>
                  <Trans>census.censusType</Trans>
                </p>
                <FormField>
                  <Field
                    name="pollbookId"
                    component={RadioButtonGroup as any}
                    validate={(value: any) => (value ? undefined : 'Required')}
                    options={pollBookOptions}
                  />
                </FormField>
                {pollbookIntegration && importButton ? (
                  <div>
                  <p className={classes.formHeader}>
                    <Trans>census.censusUnit</Trans>
                  </p>
                  <FormField>
                    <Field
                      name="ouIds"
                      component={OrgregTree as any}
                      onUnitsChange={this.handleUnitsChange}
                    />
                  </FormField>
                </div>) :
                  this.censusFromFile(classes, fileName)
                }
              </form>
              <ButtonContainer alignLeft>
                {pollbookIntegration && importButton ? <Button
                  text={<Trans>general.import</Trans>}
                  disabled={pristine || invalid || isUploading || this.state.unitsChecked.length === 0}
                  showSpinner={isUploading}
                  action={submitWrapperUnits}
                  key="saveButton"
                /> :
                <Button
                  text={<Trans>general.upload</Trans>}
                  disabled={pristine || invalid || isUploading}
                  showSpinner={isUploading}
                  action={submitWrapper}
                  key="saveButton"
                /> }
              </ButtonContainer>
            </div>
          </Box>
        </Container>
      </div>
    );
  }

  public render() {
    return <Form onSubmit={this.onSubmit} render={this.renderForm} />;
  }
}

export default injectSheet(styles)(
  withTranslation()(withApollo<IProps, IState>(UploadCensusFileModal))
);
